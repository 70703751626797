div.wrapper {
  display: flex;
  flex-direction: column;
}

footer {
  background-color: #2a2f33;
  padding: .5rem;
}

footer p {
  color: whitesmoke;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;
}

header {
  text-align: center;
  padding: 2rem;
}

header img {
  max-height: 10rem;
  max-width: 100%;
  text-align: center;
}

div#error-page {
  background-color: white;
  border-radius: 1rem;
  margin: 0 2rem 2rem 2rem;
  padding: 1rem;
}